<template>
	<div class="page edit-page">
		<!-- <div class="top-wrapper">
			<div class="actions">
				<el-button type="primary" plain size="small" class="submit" @click="goBack"> 返回 </el-button>
			</div>
		</div> -->

		<div class="content-wrapper">
			<el-row :gutter="20" type="flex" class="content-row">
				<el-col :lg="15" :md="15" :sm="15" :xs="24">
          <div class="content-actions">
				    <el-button type="primary" plain size="small" class="submit" @click="goBack"> 返回 </el-button>
          </div>
          <div class="player-container">
            <VideoPlayer v-if="videoLink" :key="id" :videoLink="videoLink" :poster="videoImg"></VideoPlayer>
          </div>
          <div class="video-info">
            <div class="video-owner">
              <img :src="assets.ricohLogo" />
            </div>
            <div class="video-content">
              <div class="company"> 理光技术中心 </div>
              <div class="video-title"> {{ title }} </div>
            </div>
          </div>
          <div class="attention"> 版权申明：视频版权归理光所有 仅限在线观看不可下载 </div>
				</el-col>

				<el-col :lg="9" :md="9" :sm="9" :xs="24">
					<div class="more-container pb0">
						<div class="title-wrapper">
							<div class="title"> 更多相关视频 </div>
						</div>

						<div v-if="moreVideoList && moreVideoList.length > 0" class="more-video-list">
              <!-- <div class="video-list-content"> -->
                <div v-for="(item, index) of moreVideoList" :key="index" class="video-item">
                  <div class="video-item__content">
                    <VideoItem :video="item" :showAction="false" size="small"></VideoItem>
                  </div>
                </div>
              <!-- </div> -->
						</div>
						<div v-else class="empty">
							<img :src="assets.emptyImg" class="empty-img" />
							<div class="empty-description"> 暂无相关视频~ </div>
						</div>
					</div>
				</el-col>
			</el-row>
		</div>
	</div>
</template>

<script>
import VideoItem from '@/components/VideoItem'
import VideoPlayer from '@/components/VideoPlayer'
import kong from '@/assets/images/kong.png'
import ricohLogo from '@/assets/images/ricoh.png'

export default {
  components: { VideoPlayer, VideoItem },
  data() {
    return {
      // 视频ID
      id: '',
      cached: {
        searchKey: '',
        deviceTypeId: '',
        deviceNameId: '',
        sortType: 1,
        publishFlag: '',
      },
      // 视频数据
      data: '',
      moreVideoList: [],
      assets: {
        emptyImg: kong,
        ricohLogo: ricohLogo
      }
    }
  },
  computed: {
    // 视频链接
    videoLink() {
      let link = ''
      if (this.data && this.data.videoLink) link = this.data.videoLink
      return link
    },
    // 视频封面图
    videoImg() {
      let url = ''
      if (this.data && this.data.videoImg) url = this.data.videoImg
      return url
    },
    // 视频主题
    title() {
      let _title = ''
      if (this.data && this.data.title) _title = this.data.title
      return _title
    }
  },
  watch: {
    $route(route) {
      console.log('路由改变..')
      this.id = this.$route.query.id || ''
      this.getDetail()
      this.getMoreVideos()
    }
  },
  created() {
    this.id = this.$route.query.id || ''

    this.cached.searchKey = this.$route.params.searchKey || ''
    this.cached.deviceTypeId = this.$route.params.deviceTypeId || ''
    this.cached.deviceNameId = this.$route.params.deviceNameId || ''
    this.cached.sortType = this.$route.params.sortType || 1
    this.cached.publishFlag = this.$route.params.publishFlag === 0 ? 0 : ''

    this.getDetail()
    this.getMoreVideos()
  },
  methods: {
    // 获取视频详情
    getDetail() {
      if (!this.id) return

      let that = this
      that.$request.post(
        "dataVideoInfo",
        true, {
          id: that.id
        },
        function (r) {
          if (r.code == "0") {
            that.data = r.data
            that.addVideoClickNum()
          } else {
            that.$message.error(r.msg)
          }
        }
      )
    },
    // 增加视频点击量
    addVideoClickNum() {
      let that = this
      that.$request.post(
        "dataVideoClick",
        false, {
          id: that.id
        },
        function (r) {
          console.log('视频点击量 +1')
        }
      )
    },
    // 获取更多视频
    getMoreVideos() {
      if (!this.id) return

      let that = this
      that.$request.post(
        "dataVideoMore",
        false, {
          id: that.id
        },
        function (r) {
          if (r.code == "0") {
            that.moreVideoList = r.data
          } else {
            that.$message.error(r.msg)
          }
        }
      )
    },
    goBack() {
      this.$router.push({
        name: 'video',
        params: this.cached
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.page {
  background-color: white;

  .content-actions {
    padding-bottom: 13px;

    .el-button {
      background-color: #117FFC;
      border-color: #117FFC;
      width: 66px;
      height: 32px;
      line-height: 13px;
      border-radius: 4px;
      font-size: 15px;
    }
    .submit {
      height: 32px;
      border: none;
      color: rgba(255, 255, 255, 1);
      background-color: rgba(17, 127, 252, 1);

    }
  }
}
.video-info {
  display: flex;
  padding: 20px 0;
  // align-items: center;

  .video-owner {
    img {
      width: 60px;
      height: 60px;
    }
  }
  .video-content {
    margin-left: 10px;

    .company {
      font-size: 16px;
      font-weight: bold;
    }
    .video-title {
      margin-top: 10px;
      font-size: 14px;
    }
  }
}
.attention {
  margin-top: 20px;
  font-size: 12px;
  color: #A8A8A8;
}
.more-container {
  .title-wrapper {
    .title {
      padding-bottom: 9px;
      height: 30px;
      line-height: 30px;
    }
  }
  .more-video-list {
    display: flex;
    flex-wrap: wrap;
    max-height: calc(100vh - 230px);
    overflow-y: scroll;
    position: relative;

    .video-item {
      width: 50%;

      .video-item__content {
        padding: 6px;

        .video-item-wrapper {
          width: 100%;
        }
      }
    }
  }
}
</style>

<style lang="scss">
// 时间进度显示
.video-js .vjs-time-control{display:block;}

// 剩余时间隐藏
.video-js .vjs-remaining-time{display: none;}

// 暂停时显示按钮
.vjs-paused .vjs-big-play-button,
.vjs-paused.vjs-has-started .vjs-big-play-button {
    display: block;
}
// 处理视频封面黑边
.vjs-poster {
  background-size: cover;
  background-color: white;
}
</style>
